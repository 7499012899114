<template>
  <div id="index">
    <dv-full-screen-container class="bg">
      <div class="host-body">
        <!-- 第二行 -->
        <Menu name="device-tower"
              @change="onProInfoChange($event)" />
        <dv-loading v-if="loading">Loading...</dv-loading>
        <div v-else
             id="content">
          <div id="center">
            <div class="mapContainer"
                 :style="{
                backgroundImage: bgImg,
                backgroundSize: '100% 100%',
                backgroundRepeat: 'no-repeat'
              }">
              <div v-for="item in showDevices"
                   v-bind:key="item.Id"
                   v-bind:name="item.Name"
                   v-on:click="clickDevice(item)"
                   :style="{
                  left:
                    (item.Pos ? item.Pos.Lat : 0) -
                    ((item.Config.FrontArmLength * 2 - 20) * boxWidth) / 1480 +
                    'px',
                  top:
                    (item.Pos ? item.Pos.Lng : 0) -
                    ((item.Config.FrontArmLength * 2 - 20) * boxWidth) / 1480 +
                    'px',
                  width:
                    ((item.Config.FrontArmLength * 2 * boxWidth) / 1480) * 2 +
                    'px',
                  height:
                    ((item.Config.FrontArmLength * 2 * boxWidth) / 1480) * 2 +
                    'px',
                  position: 'absolute'
                }">
                <div v-if="item.Status === 2"
                     :style="{
                    background: 'rgba(0, 60, 255, 0.6)'
                  }"
                     style="border-radius: 50%;filter:alpha(Opacity=80);position: absolute;width:100%;height: 100%;">
                  <img v-bind:id="item.Id"
                       v-bind:name="item.Name"
                       v-bind:title="item.Name"
                       src="@/assets/image/tower/crane-online.png"
                       class="position"
                       :style="{
                      position: 'absolute',
                      left:
                        ((item.Config.FrontArmLength * 2 - 20) * boxWidth) /
                          1480 +
                        'px',
                      top:
                        ((item.Config.FrontArmLength * 2 - 20) * boxWidth) /
                          1480 +
                        'px',
                      background: 'red'
                    }" />
                  <img class="position-pointer"
                       style=""
                       :style="{
                      position: 'absolute',
                      width:
                        (2 * (item.Config.FrontArmLength * 2) * boxWidth) /
                          1480 +
                        'px',
                      transform: 'rotate(' + item.Record.Angle + 'deg)'
                    }"
                       src="@/assets/image/tower/crane-cursor.png" />
                </div>
                <div v-else-if="item.Status == 1"
                     :style="{
                    background: 'rgba(80, 80, 80, 0.6)'
                  }"
                     style="border-radius: 50%;filter:alpha(Opacity=80);position: absolute;width: 100%;height: 100%;">
                  <img v-bind:id="item.Id"
                       v-bind:name="item.Name"
                       v-bind:title="item.Name"
                       src="@/assets/image/tower/crane-offline.png"
                       class="position"
                       :style="{
                      position: 'absolute',
                      background: 'white',
                      left:
                        ((item.Config.FrontArmLength * 2 - 20) * boxWidth) /
                          1480 +
                        'px',
                      top:
                        ((item.Config.FrontArmLength * 2 - 20) * boxWidth) /
                          1480 +
                        'px'
                    }" />

                  <img class="position-pointer"
                       style=""
                       :style="{
                      position: 'absolute',
                      width:
                        (2 * (item.Config.FrontArmLength * 2) * boxWidth) /
                          1480 +
                        'px',
                      transform: 'rotate(' + item.Record.Angle + 'deg)'
                    }"
                       src="@/assets/image/tower/crane-cursor.png" />
                </div>

                <div v-else
                     :style="{
                    background: 'rgb(216,30,6,0.6)'
                  }"
                     style="border-radius: 50%;filter:alpha(Opacity=80);position: absolute;width: 100%;height: 100%;">
                  <img v-bind:id="item.Id"
                       v-bind:name="item.Name"
                       v-bind:title="item.Name"
                       v-bind:status="item.Status"
                       src="@/assets/image/tower/crane-worry.png"
                       class="position"
                       :style="{
                      position: 'absolute',
                      background: 'red',
                      left:
                        ((item.Config.FrontArmLength * 2 - 20) * boxWidth) /
                          1480 +
                        'px',
                      top:
                        ((item.Config.FrontArmLength * 2 - 20) * boxWidth) /
                          1480 +
                        'px'
                    }" />
                  <img class="position-pointer"
                       style=""
                       :style="{
                      position: 'absolute',
                      width:
                        (2 * (item.Config.FrontArmLength * 2) * boxWidth) /
                          1480 +
                        'px',
                      transform: 'rotate(' + item.Record.Angle + 'deg)'
                    }"
                       src="@/assets/image/tower/crane-cursor.png" />
                </div>
              </div>
            </div>
          </div>
          <!-- 右边 -->
          <div id="right">
            <div class="up">
              <h3>近期报警</h3>
              <dv-scroll-board :config="config"
                               ref="alarmBox" />
            </div>
            <div class="down">
              <h3>设备列表</h3>
              <div class="leftMain"
                   style="overflow: auto;height: 100%;">
                <div class="monitor-device-list-link flex-column align-center module"
                     style="position: relative; z-index: 1;overflow: auto;">
                  <div class="monitor-device-item-link flex-row align-center"
                       v-for="item in showDevices"
                       v-bind:key="item.Id"
                       v-on:click="clickDevice(item)">
                    <div>
                      <span class="trans-o-left">{{
                        item.EquipmentRecordNo
                      }}</span>
                      <span class="trans-o-left">{{ item.Name }}</span>
                      <span class="trans-o-left">{{ item.Code }}</span>
                      <span v-if="item.Status == 1"
                            class="trans-o-left">离线</span>
                      <span v-else-if="item.Status == 2"
                            class="trans-o-left">在线</span>
                      <span v-else
                            class="trans-o-left">离线</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <tower-detail ref="tower"
                    :parentObj="this"></tower-detail>
    </dv-full-screen-container>
  </div>
</template>

<script>
import Menu from "@/components/Menu";
import ProInfoCache from "@/utils/cache/ProInfoCache";
import TowerDetail from "./tower-detail.vue";
const synth = window.speechSynthesis;
const msg = new SpeechSynthesisUtterance();
const alarmTemplat = {
  header: ["塔吊", "时间", "描述"],
  data: [],
  rowNum: 7, //表格行数
  headerHeight: 35,
  headerBGC: "#0f1325", //表头
  oddRowBGC: "#0f1325", //奇数行
  evenRowBGC: "#171c33", //偶数行
  index: true,
  columnWidth: [20, 90, 150, 120],
  align: ["center"],
  hoverPause: true
};
moment.locale("zh-cn");
import moment from "moment";
export default {
  data () {
    return {
      synth,
      msg,
      loading: true,
      connection: {},
      defualtBgImg: "url(" + require("@/assets/image/morenditu.png") + ")",
      bgImg: {},
      devices: {},
      showDevices: [],
      menu: [],
      boxWidth: 1480,
      config: alarmTemplat
    };
  },
  components: {
    Menu,
    TowerDetail
  },

  created: function () {
    this.loading = false;
    let connection = this.$op.getConnect(this);
    connection.on("towerrealtime", x => {
      console.log(x);
      //报警
      if (x.data.noAlarm == 0 || x.data.noError == 0) {
        if (x.data.windSpeedAlarm == 1) {
          this.handleSpeak(x.device.name + "风速报警");
          this.config.data.push([x.device.name, x.data.recordDate, "风速报警"]);
        }
        if (x.data.momentAlarm == 1) {
          this.handleSpeak(x.device.name + "力矩报警");
          this.config.data.push([x.device.name, x.data.recordDate, "力矩报警"]);
        }
        if (x.data.heightAlarm == 1) {
          this.handleSpeak(x.device.name + "高度报警");
          this.config.data.push([x.device.name, x.data.recordDate, "高度报警"]);
        }
        if (x.data.minRangeAlarm == 1) {
          this.handleSpeak(x.device.name + "幅度报警");
          this.config.data.push([
            x.device.name,
            x.data.recordDate,
            "幅度内限位报警"
          ]);
        }

        if (x.data.maxRangeAlarm == 1) {
          this.handleSpeak(x.device.name + "幅度报警");
          this.config.data.push([
            x.device.name,
            x.data.recordDate,
            "幅度外限位报警"
          ]);
        }
        if (x.data.obliguityAlarm == 1) {
          this.handleSpeak(x.device.name + "倾角报警");
          this.config.data.push([x.device.name, x.data.recordDate, "倾角报警"]);
        }
        if (x.data.windSpeedError == 1) {
          this.handleSpeak(x.device.name + "风速传感器故障");
          this.config.data.push([
            x.device.name,
            x.data.recordDate,
            "风速传感器故障"
          ]);
        }
        if (x.data.rangeError == 1) {
          this.handleSpeak(x.device.name + "幅度传感器故障");
          this.config.data.push([
            x.device.name,
            x.data.recordDate,
            "幅度传感器故障"
          ]);
        }
        if (x.data.heightError == 1) {
          this.handleSpeak(x.device.name + "高度传感器故障");
          this.config.data.push([
            x.device.name,
            x.data.recordDate,
            "高度传感器故障"
          ]);
        }
        if (x.data.weightError == 1) {
          this.handleSpeak(x.device.name + "重量传感器故障");
          this.config.data.push([
            x.device.name,
            x.data.recordDate,
            "重量传感器故障"
          ]);
        }
        if (x.data.angleError == 1) {
          this.handleSpeak(x.device.name + "角度传感器故障");
          this.config.data.push([
            x.device.name,
            x.data.recordDate,
            "角度传感器故障"
          ]);
        }
        if (x.data.obliguityError == 1) {
          this.handleSpeak(x.device.name + "倾角传感器故障");
          this.config.data.push([
            x.device.name,
            x.data.recordDate,
            "倾角传感器故障"
          ]);
        }
        this.$refs["alarmBox"].updateRows(this.config.data);
      }
    });
    // connection.on("toweralarm", (x) => {
    //     console.log(x)
    // });
  },
  mounted () {
    this.boxWidth = document.body.clientWidth;
    window.onresize = () => {
      return (() => {
        this.boxWidth = document.body.clientWidth;
      })();
    };
    this.pro = ProInfoCache.getProInfo();
    this.initForm();
  },

  watch: {},
  methods: {
    onProInfoChange (e) {
      this.config.data = [];
      this.devices = [];
      this.showDevices = [];
      this.pro = e;
      this.initForm();
    },
    initForm () {
      this.loadData();
    },
    loadData () {
      this.$http
        .post("/Projects/ProImage/GetTheProjectImageData", {
          ProId: this.pro.Id
        })
        .then(resJson => {
          this.loading = false;
          if (resJson.Success && resJson.Data)
            this.bgImg = "url(" + resJson.Data + ")";
          else this.bgImg = this.defualtBgImg;
        });
      this.fetchEventsList();
    },
    fetchEventsList () {
      if (!this.pro.Id) return;
      this.loading = true;
      this.$http
        .post("/DeviceManage/DeviceTower/GetDeviceSelfPosInfo", {
          ProId: this.pro.Id
        })
        .then(resJson => {
          this.loading = false;
          this.devices = resJson.Data;
          this.showDevices = this.devices;
          //this.initMenu();
          for (let index = 0; index < this.showDevices.length; index++) {
            const element = this.showDevices[index];
            if (element.Record.NoAlarm == 0 || element.Record.NoError == 0) {
              if (element.Record.WindSpeedAlarm == 1)
                this.config.data.push([
                  element.Name,
                  element.Record.RecordDate,
                  "风速报警"
                ]);
              if (element.Record.MomentAlarm == 1)
                this.config.data.push([
                  element.Name,
                  element.Record.RecordDate,
                  "力矩报警"
                ]);
              if (element.Record.HeightAlarm == 1)
                this.config.data.push([
                  element.Name,
                  element.Record.RecordDate,
                  "高度报警"
                ]);
              if (element.Record.MinRangeAlarm == 1)
                this.config.data.push([
                  element.Name,
                  element.Record.RecordDate,
                  "幅度内限位报警"
                ]);
              if (element.Record.MaxRangeAlarm == 1)
                this.config.data.push([
                  element.Name,
                  element.Record.RecordDate,
                  "幅度外限位报警"
                ]);
              if (element.Record.ObliguityAlarm == 1)
                this.config.data.push([
                  element.Name,
                  element.Record.RecordDate,
                  "倾角报警"
                ]);
              if (element.Record.ObliguityAlarm == 1)
                this.config.data.push([
                  element.Name,
                  element.Record.RecordDate,
                  "倾角报警"
                ]);
              if (element.Record.WindSpeedError == 1)
                this.config.data.push([
                  element.Name,
                  element.Record.RecordDate,
                  "风速传感器故障"
                ]);
              if (element.Record.RangeError == 1)
                this.config.data.push([
                  element.Name,
                  element.Record.RecordDate,
                  "幅度传感器故障"
                ]);
              if (element.Record.HeightError == 1)
                this.config.data.push([
                  element.Name,
                  element.Record.RecordDate,
                  "高度传感器故障"
                ]);
              if (element.Record.WeightError == 1)
                this.config.data.push([
                  element.Name,
                  element.Record.RecordDate,
                  "重量传感器故障"
                ]);
              if (element.Record.AngleError == 1)
                this.config.data.push([
                  element.Name,
                  element.Record.RecordDate,
                  "角度传感器故障"
                ]);
              if (element.Record.ObliguityError == 1)
                this.config.data.push([
                  element.Name,
                  element.Record.RecordDate,
                  "倾角传感器故障"
                ]);
              this.$refs["alarmBox"].updateRows(this.config.data);
            }
          }
        });
    },

    clickDevice (d) {
      this.visible = true;
      this.$refs.tower.openForm(d);
    },
    cancelLoading () {
      setTimeout(() => {
        this.loadData();
      }, 10 * 60 * 60 * 1000);
    },
    /** 语音播报的函数**/
    handleSpeak (e) {
      this.msg.text = e;
      this.msg.lang = "zh-CN";
      this.msg.volume = "1";
      this.msg.rate = 1;
      this.msg.pitch = 1;
      this.synth.speak(this.msg);
    }
  }
};
</script>

<style lang="scss">
.danger {
  color: #fb497c !important;
}
.noml {
  color: #24e974 !important;
}
.huang {
  color: #ffb70e !important;
}
#content {
  display: flex;
  width: 100%;
  padding: 0.5rem 0.3rem 0 0.3rem;
  height: 10.3rem;
  overflow: hidden;
  // background-image: url('./2.5.png');
  h3 {
    font-weight: 900;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: rgb(249 242 242 / 85%);
  }
  #center {
    width: 1430px;
    height: 784px;
    margin: 0 auto;
    background: #03263d;
    position: relative;
  }

  #right {
    height: 100%;
    margin-left: 1.4%;
    .up {
      display: block;
      width: 4.74rem;
      height: 4.61rem;
      background: url("../../assets/image/g_youshang.png") no-repeat center
        center;
      background-size: 100% 100%;
      .title {
        width: 3.72rem;
        height: 0.46rem;
        background-color: #071038;
        margin: 0 auto;
        color: #fff;
        line-height: 0.46rem;
        font-size: 0.16rem;
        padding-left: 0.34rem;
        .content {
          margin-left: 0.1rem;
          .danwei {
            font-size: 0.12rem;
          }
        }
      }
      .imgs {
        display: flex;
        justify-content: space-around;
        margin-top: 0.2rem;
        padding: 0 0.35rem;
        div {
          width: 0.84rem;
          height: 0.84rem;
          .text {
            color: #ffb70e;
            text-align: center;
            margin-top: 0.2rem;
            p {
              &:nth-child(1) {
                font-size: 0.18rem;
              }
              &:nth-child(2) {
                margin-top: -0.05rem;
                font-size: 0.12rem;
              }
            }
          }
        }
        .daydian {
          background: url("../../assets/image/g_ydhuang.png") no-repeat center
            center;
          background-size: 100% 100%;
        }
        .dianxiang {
          background: url("../../assets/image/g_ydhuang.png") no-repeat center
            center;
          background-size: 100% 100%;
        }
        .loudian {
          background: url("../../assets/image/g_ydhuang.png") no-repeat center
            center;
          background-size: 100% 100%;
          .text {
            color: #ffb70e;
          }
        }
        .loudian1 {
          background: url("../../assets/image/g_ydhong.png") no-repeat center
            center;
          background-size: 100% 100%;
          .text {
            color: #fb497c;
          }
        }
      }
      .subtitle {
        display: flex;
        justify-content: space-around;
        text-align: center;
        padding: 0 0.35rem;
        margin-top: 0.1rem;
        p {
          width: 0.84rem;
        }
      }
      .door-state {
        width: 3.44rem;
        color: #fff;
        margin: 0 auto;
        margin-top: 0.2rem;
        .door-title {
          font-size: 0.14rem;
          line-height: 0.29rem;
        }
        .door-data {
          height: 1.4rem;
          overflow: hidden;
          margin-top: 0.1rem;
          ul {
            li {
              display: flex;
              justify-content: space-between;
              line-height: 0.35rem;
            }
          }
        }
      }
      .door-wrap {
        width: 100%;
        color: #fff;
        padding-top: 0.2rem;
        height: 2.1rem;
        overflow: hidden;
        .table-header {
          display: flex;
          justify-content: space-between;
          padding: 0 0.35rem;
        }
        .table-rows {
          display: flex;
          justify-content: space-between;
          padding: 0 0.35rem;
        }
      }
    }
    .down {
      margin-top: 0.3rem;
      width: 4.74rem;
      height: 4.6rem;
      background: url("../../assets/image/g_youxia.png") no-repeat center center;
      background-size: 100% 100%;
      .title {
        width: 3.72rem;
        height: 0.46rem;
        background-color: #071038;
        margin: 0 auto;
        color: #fff;
        line-height: 0.46rem;
        font-size: 0.16rem;
        padding-left: 0.34rem;
        .content {
          margin-left: 0.1rem;
          .danwei {
            font-size: 0.12rem;
          }
        }
      }
      .one {
        color: #fff;
        .mintitle {
          position: absolute;
          margin-top: 0.3rem;
          transform: translateX(-0.2rem);
          color: #fff;
          span {
            &:nth-child(1) {
              float: left;
              margin-left: 0.5rem;
              font-size: 0.14rem;
              transform: translateY(-0.2rem);
            }
            &:nth-child(2) {
              float: right;
              transform: translateY(-0.05rem);
              margin-left: 2.1rem;
              font-size: 0.12rem;
              &::before {
                position: absolute;
                content: "";
                width: 0.06rem;
                height: 0.06rem;
                border-radius: 50%;
                background-color: #fff;
                right: 0.68rem;
                top: 0.1rem;
              }
            }
          }
        }
        .sevenworter {
          transform: translate(0.15rem, 0.35rem);
        }
      }
      .two {
        color: #fff;
        .mintitle {
          position: absolute;
          margin-top: 0.5rem;
          transform: translateX(-0.2rem);
          color: #fff;
          span {
            &:nth-child(1) {
              float: left;
              margin-left: 0.5rem;
              font-size: 0.14rem;
              transform: translateY(-0.2rem);
            }
            &:nth-child(2) {
              float: right;
              transform: translateY(-0.05rem);
              margin-left: 2.1rem;
              font-size: 0.12rem;
              &::before {
                position: absolute;
                content: "";
                width: 0.06rem;
                height: 0.06rem;
                border-radius: 50%;
                background-color: #fff;
                right: 0.68rem;
                top: 0.1rem;
              }
            }
          }
        }
        .sixworter {
          transform: translate(0.15rem, 0.6rem);
        }
      }
    }
  }

  .mapContainer {
    height: 100%;
    margin: 0 auto;
    background: #03263d;
    position: relative;
  }
  .monitor-device-item-link {
    cursor: pointer;
    background-color: rgb(15 19 37);
    margin: 0.025rem 0px;
    height: 0.35rem;
  }
  .active,
  .monitor-device-item-link:hover {
    background-color: #1a5cd7;
  }
  .flex-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -moz-box;
  }
  .align-center {
    align-items: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    text-align: center;
  }
  .trans-o-left {
    padding-left: 8.61111px;
    transform: scale(0.645833);
    font-size: 10.5px;
    color: hsla(0, 0%, 100%, 0.8);
    transform-origin: left center 0;
    -webkit-transform-origin: left center 0;
    -ms-transform-origin: left center 0;
  }
  .position {
    cursor: pointer;
    /*  position: absolute; */
    border-radius: 50%;
    top: 179px;
    left: 661px;
    width: 40px;
    height: 40px;
    z-index: 1000;
    color: #fff;
    text-shadow: #000 1px 0 0, #000 0 1px 0, #000 -1px 0 0, #000 0 -1px 0;
    -webkit-text-shadow: #000 1px 0 0, #000 0 1px 0, #000 -1px 0 0,
      #000 0 -1px 0;
    -moz-text-shadow: #000 1px 0 0, #000 0 1px 0, #000 -1px 0 0, #000 0 -1px 0;
  }
  .position-pointer {
    position: absolute;
    z-index: 0;
    left: 0;
    transition: all 2s;
    pointer-events: none;
  }
  .align-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
</style>
